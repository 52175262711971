import React from 'react';
import Image from 'components/common/Image';
import LayoutPanel from 'components/layout/layoutPanel';
import { LinkModal } from 'kh-common-components';
import { modalContents } from 'constants/modal-content';
import { useToggleRac50Expired } from 'constants/hooks/marketingEventToggles';

function CarbonOffsetInfo (props) {
  const isPromoRac50Expired = useToggleRac50Expired();

  return (
    <LayoutPanel background={props.panelBackground}
                 padding={props.panelPadding}
                 {...props}>

      <div className="container">
        <div className="row mer-panel">

          <div className="mer-panel__images col-5 col-lg-2 col-sm-3 mb-0 mr-lg-5">
              	<Image className="mer-panel__image"
					   src="/icons/flowmoji/flowmoji-offsets.svg"
					   alt="Flowmoji offset" />
          </div>
          <div className="content col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 offset-lg-0">
              <h3>Go carbon neutral</h3>
              <p>If you opt-in to our Carbon Offset Program, your contribution will cover the cost of acquiring offset units to balance out the emissions relating to your natural gas usage<sup>{isPromoRac50Expired ? '✝' : '*'}</sup>.</p>
              <div>                  
                  <small><LinkModal {...props}
                  modalContents={modalContents}
                  useModal="true"
                  title="Terms & Conditions"
                  // linkClass=""
                  displayContent={<span><sup>{isPromoRac50Expired ? '✝' : '*'}</sup>Terms and conditions apply</span>}
                  modalTitle="Terms & Conditions"
                  modalContentName="terms-carbonoffset-opt-in"
                  okayButtonLabel="Close"
                  /></small>
              </div>
          </div>
        </div>
      </div>
    </LayoutPanel>
  )
}

export default CarbonOffsetInfo;