import React from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";

import AddressSearchInput from "components/common/AddressSearchInput"
import Image from 'components/common/Image';

import BlogCarousel from 'components/cards/BlogCarousel';
import Faq from 'components/common/Faq';
import NewHomeInfo from "components/cards/NewHomeInfo";
import CarbonOffsetInfo from "components/cards/CarbonOffsetInfo";
import RacInfo from "components/cards/RacInfo";
import CompetitionDyson from "components/cards/CompetitionDyson";

import faqData from 'constants/components/accordion-list/momi-faq';
import heroBanner from 'images/moving-house/hero-skateboard-boxes.png';

function MovingHouse() {
	return (
		<>
			<SEO title="Move your gas with Kleenheat"
				 description="We take the stress out of moving house with quick and easy options for existing customers and those ready to switch to Kleenheat." />

            <Header />

        	<LayoutMain>

	    		{/* hero banner */}
	    		<LayoutPanel theme="mer-theme--cyan"
		                     background="mer-bg--primary-cyan"
		                     id="moving-house-hero-banner"
		                     padding="mer-py-lg">
	                <div className="container">
	                    <div className="row mer-panel align-items-center justify-content-center">

	                        <div className="col-10 col-sm-7 col-lg-6 mer-text--size-lg">
	                            <div className="content">
	                                <h1 className="mb-0 mer-typography--headline2">We make moving your gas easy.</h1>
									<p>Moving home? Stay with Kleenheat or make the switch in minutes. Either way, we're here to keep things simple... with absolutely no song and dance.</p>
	                            </div>
	                            
	                            <form className="mt-3 mt-lg-5 mb-0">
	                                <fieldset>
	                                    <legend>What address are you moving to?</legend>
	                                    <div className="row">
	                                        <div className="col-12 col-lg-10">
	                                            <AddressSearchInput searchButtonText="Move now"
	                                                                showLabel="false"
	                                                                searchIcon='search'
	                                                                isSsfRedirect={true} />
	                                        </div>
	                                    </div>
	                                </fieldset>
	                            </form>
	                        </div>

	                        <div className="mer-panel__images hero-banner col-8 col-sm-5 col-lg-4 col-xl-5">
	                            <img src={heroBanner} 
	                                 className="mer-panel__image"
	                                 alt="Moving house trolley" />
	                        </div>

	                        
	                    </div>
	                </div>
	            </LayoutPanel>

		        <CompetitionDyson />

	            <NewHomeInfo />

	        	{/* main usp panel? */}
	            <LayoutPanel theme="mer-theme--light mer-bg--ui-light"
	        				 padding="mer-pt-lg pb-0">
	                <div className="container">
	                    <div className="row mer-panel">
	                    	
	                        <div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg text-center">
	                            <div className="content">
	                                <h1 className="mer-color--primary-cyan">
	                                	We make moving easy
	                                </h1>
	                                <p>
	                                	Moving can be stressful but taking Kleenheat with you doesn’t have to be. We offer quick and easy solutions for existing customers and those ready to enjoy better value with WA’s local gas provider.
	                                </p>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </LayoutPanel>

	        	{/* pack up discounts */}
	        	{/*<LayoutPanel background="mer-bg--ui-light"
			                 padding="mer-py-de">
					<div className="container">
						<div className="row mer-panel">
							<div className="mer-panel__images col-5 col-lg-2 col-sm-3 mb-0 mr-lg-5">
								<Image className="mer-panel__image"
				                       src="/icons/flowmoji/flowmoji-movingtruck-discount.svg"
				                       alt="Flowmoji moving truck discount"
				                />
							</div>
							<div className="content col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 offset-lg-0">
								<h3>Pack up your discounts</h3>
								<p>Take Kleenheat with you and continue to enjoy genuine ongoing value. Transfer your account to a new address in just a few minutes online.</p>
							</div>
						</div>
					</div>
			    </LayoutPanel>*/}

	        	{/* less stress */}
	        	<LayoutPanel background="mer-bg--ui-light"
			                 padding="mer-py-de">
					<div className="container">
						<div className="row mer-panel">
							<div className="mer-panel__images col-5 col-lg-2 col-sm-3 mb-0 mr-lg-5">
								<Image className="mer-panel__image"
				                       src="/icons/flowmoji/flowmoji-discountreminder.svg"
				                       alt="Flowmoji discount reminder"
				                />
							</div>
							<div className="content col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 offset-lg-0">
								<h3>Less stress, more savings</h3>
								<p>Unpack better value energy and settle into your new home with a plan to suit your household complete with hassle-free savings on natural gas usage charges.</p>
							</div>
						</div>
					</div>
			    </LayoutPanel>

				<CarbonOffsetInfo  panelBackground="mer-bg--ui-light"
	            		  		   panelPadding="mer-py-de" />

	            <RacInfo  panelBackground="mer-bg--ui-light"
	            		  panelPadding="mer-py-de"
	            		  momi={true} />

	           	{/* help */}
  		   	  	<LayoutPanel background="mer-bg--ui-light"
			                 padding="mer-py-de mer-pb-ju">
					<div className="container">
						<div className="row mer-panel">
							<div className="mer-panel__images col-5 col-lg-2 col-sm-3 mb-0 mr-lg-5">
								<Image className="mer-panel__image"
				                       src="/icons/flowmoji/flowmoji-agent.svg"
				                       alt="Flowmoji customer service officer"
				                />
							</div>
							<div className="content col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 offset-lg-0">
								<h3>We're here to help</h3>
								<p>If you have questions or just want to talk to a real person, try calling our dedicated moving hotline on <a href="tel:1300650687" title="Speak with our customer service team">1300 650 687</a> to speak with our award-winning customer service team.</p>
							</div>
						</div>
					</div>
			    </LayoutPanel>

			  	<Faq panelBackground="mer-bg--gs-grey-lighter"
		  			 panelPadding="mer-py-lg"
		  			 data={faqData} />

  			    <BlogCarousel id="stories"
            			  	  title="Advice for a smooth move"
            			  	  // apiQuery="?tag=moving-house&posts_per_page=6"
            			  	  apiQuery="?category=moving-house"
    			/>
        	</LayoutMain>
            <Footer />
		</>
		
	);
}

export default MovingHouse;
